<template>
  <div id="receiveAccount">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_004')}`" />
    <div v-if="!isEmpty" class="common-card-body">
      <div class="edit-btn">
        <a-button
          shape="round"
          type="primary"
          @click="$router.push('/receive/apply_type')"
        >
          <template #icon><plus-outlined /></template>
          {{ $t('pages_receive_001') }}
        </a-button>
        <a-button
          shape="round"
          type="primary"
          ghost
          style="margin-left: 12px"
          :loading="exportLoading"
          @click="exportAccounts"
        >
          <template #icon><download-outlined /></template>
          {{ exportLoading ? $t('common_text_023') : $t('pages_receive_002') }}
        </a-button>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_003') }}: </span>
          <a-input
            v-model:value="searchQuery.tradeBankAccount"
            allow-clear
            @pressEnter="loadVaList(1)"
            @blur="loadVaList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_004') }}: </span>
          <a-input
            v-model:value="searchQuery.tradeAccountName"
            allow-clear
            @pressEnter="loadVaList(1)"
            @blur="loadVaList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_005') }}: </span>
          <a-input
            v-model:value="searchQuery.simpleName"
            allow-clear
            @pressEnter="loadVaList(1)"
            @blur="loadVaList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_012') }}: </span>
          <a-select
            v-model:value="searchQuery.currencies"
            allow-clear
            show-search
            option-label-prop="label"
            option-filter-prop="label"
            @change="loadVaList(1)"
          >
            <a-select-option
              v-for="item in curList"
              :key="item.currency"
              :value="item.currency"
              :label="`${item.currency} - ${item.currencyName}`"
            >
              <img :src="getCountryLogo(item.currency)" class="select-img-country">
              <span>{{ item.currency }} - {{ item.currencyName }}</span>
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_007') }}: </span>
          <a-select
            v-model:value="searchQuery.areaCode"
            allow-clear
            show-search
            option-filter-prop="label"
            @change="loadVaList(1)"
          >
            <a-select-option
              v-for="area in areaPubList"
              :key="area.areaCode"
              :value="area.areaCode"
              :label="`${area.areaEnglishName }-${area.areaChineseName}`"
            >{{ area.areaEnglishName }}-{{ area.areaChineseName }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_receive_008') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            @change="loadVaList(1)"
          >
            <a-select-option
              v-for="status in applyVaStatus"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1200 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="receiveList"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'currency'">
              <div class="flex-align">
                <img style="width: 21px; height: 13px" :src="getCountryLogo(record.oneCurrency)">
                <span style="margin-left: 5px">{{ record.oneCurrency }}</span>
              </div>
            </template>
            <template v-else-if="column.key === 'edit'">
              <a-space style="width: 100%; justify-content: space-between">
                <a-button
                  v-show="['00', '60'].includes(record.status)"
                  shape="round"
                  size="small"
                  class="btn-primary-empty-0"
                  @click="$router.push({ path: '/receive/account_detail', query: { id: record.id } })"
                >{{ $t('pages_receive_009') }}</a-button>
                <a-button
                  v-if="['00', '60'].includes(record.status)"
                  shape="round"
                  size="small"
                  type="primary"
                  @click="$router.push({ path: '/receive/manage', query: { receiveNo: record.tradeBankAccount } })"
                >{{ $t('pages_receive_010') }}</a-button>
                <a-button
                  v-if="record.status === '90'"
                  shape="round"
                  size="small"
                  danger
                  type="primary"
                  @click="reApply(record)"
                >{{ $t('pages_receive_011') }}</a-button>
              </a-space>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
    <div v-else class="common-card-body">
      <div class="account-header">
        <div class="main-title">{{ $t('pages_receive_163') }}</div>
        <div class="sub-title">{{ $t('pages_receive_164') }}</div>
      </div>
      <div class="account-body">
        <img src="~@/assets/images/no-account.png" class="main-img">
        <div class="flex-align apply-btn">
          <a-button
            shape="round"
            type="primary"
            @click="$router.push('/receive/apply_type')"
          >{{ $t('pages_receive_165') }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import { _receive } from '@/api/index'
import i18n from '@/locale/index'
import { getCountryLogo, getAttrStatusList, getCountryName, getTradeStatusName } from '@/util'
import businessList from '@/common/business'
export default {
  name: 'ReceiveAccount',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage
  },
  setup() {
    const router = useRouter()

    const state = reactive({
      loading: true,
      exportLoading: false,
      total: 0,
      searchQuery: sessionStorage.getItem('receiveAccQuery') ? JSON.parse(sessionStorage.getItem('receiveAccQuery')) : {
        pageNum: 1,
        pageSize: 10,
        tradeBankAccount: '',
        tradeAccountName: '',
        simpleName: '',
        currencies: '',
        areaCode: '',
        status: ''
      },
      isEmpty: false,
      areaPubList: [],
      curList: [],
      receiveList: [],
      columns: [
        { key: 'currency', title: i18n.global.t('pages_receive_012'), minWidth: 100 },
        { key: 'areaName', dataIndex: 'areaName', title: i18n.global.t('pages_receive_007'), minWidth: 150 },
        { key: 'tradeAccountName', dataIndex: 'tradeAccountName', title: i18n.global.t('pages_receive_004'), minWidth: 200, ellipsis: true },
        { key: 'tradeBankAccount', dataIndex: 'tradeBankAccount', title: i18n.global.t('pages_receive_003'), minWidth: 200, ellipsis: true },
        { key: 'statusName', dataIndex: 'statusName', title: i18n.global.t('pages_receive_008'), minWidth: 120 },
        { key: 'simpleName', dataIndex: 'simpleName', title: i18n.global.t('pages_receive_005'), minWidth: 120, ellipsis: true },
        { key: 'accountType', dataIndex: 'accountType', title: i18n.global.t('pages_receive_013'), minWidth: 120 },
        { key: 'edit', title: i18n.global.t('common_text_001'), align: 'center', fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 230 : 180 }
      ]
    })
    state.applyVaStatus = computed(() => {
      return getAttrStatusList('applyVaSelectStatus')
    })
    const init = async () => {
      const res = await _receive.queryVaList({ pageNum: 1, pageSize: 5 })
      if (res.data.succ) {
        if (res.data.data.list.length === 0) {
          state.isEmpty = true
        } else {
          await loadAreaPub()
          loadReceiveVaCur()
          loadVaList()
        }
      }
    }
    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadVaList()
    }
    const reApply = (record) => {
      const query = {
        id: record.id,
        businessType: record.businessType,
        businessChildType: record.businessChildType
      }
      if (!['20', '30'].includes(record.businessType)) {
        const business = businessList.find(item => item.value === record.businessChildType)
        query.pageName = business.pageName
        router.push({ path: '/receive/e_commerce', query })
      } else {
        delete query.businessChildType
        router.push({ path: '/receive/t_trade', query })
      }
    }
    const exportAccounts = async () => {
      state.exportLoading = true
      await _receive.exportAccountList(state.searchQuery)
      state.exportLoading = false
    }
    const loadAreaPub = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) {
        state.areaPubList = res.data.data
      }
    }
    const loadReceiveVaCur = async () => {
      const res = await _receive.getReceiveVaCur()
      if (res.data.succ) {
        state.curList = res.data.data
      }
    }
    const loadVaList = async (pageNum) => {
      state.loading = true
      if (pageNum) state.searchQuery.pageNum = pageNum
      sessionStorage.setItem('receiveAccQuery', JSON.stringify(state.searchQuery))
      const res = await _receive.queryVaList(state.searchQuery)
      if (res.data.succ) {
        state.total = res.data.data.total
        state.receiveList = res.data.data.list.map(item => {
          let areaName = ''
          if (item.areaCode) {
            item.areaCode.split(' ').map(it => {
              if (it) areaName += `${getCountryName(it, state.areaPubList)} `
            })
          }
          return Object.assign({}, item, {
            areaName: item.areaCode ? areaName : '-',
            accountType: getTradeStatusName(item.businessChildType, 'receiveBusinessChildType'),
            statusName: getTradeStatusName(item.status, 'applyVaStatus')
          })
        })
      }
      state.loading = false
    }
    init()
    return {
      ...toRefs(state),
      reApply,
      updatePage,
      getCountryLogo,
      getAttrStatusList,
      loadVaList,
      exportAccounts
    }
  }
}
</script>
<style lang="less" scoped>
#receiveAccount {
  .common-card-body {
    .edit-btn {
      display: flex;
      justify-content: flex-end;
      padding: 0 18px;
    }
    .table-part {
      margin-top: 12px;
    }
    .account-header {
      .main-title {
        font-size: 24px;
        font-weight: 600;
      }
      .sub-title {
        margin-top: 12px;
        font-weight: 500;
      }
    }
    .account-body {
      margin-top: 32px;
      padding-bottom: 48px;
      .main-img {
        width: 288px;
      }
      .apply-btn {
        margin-top: 48px;
        justify-content: center;
        .single-btn, .batch-btn {
          min-width: 135px;
        }
        .batch-btn {
          margin-left: 36px;
        }
      }
    }
  }
}
</style>

